const propertyData = {
    "url": "http://107swsandlewoodloop.com",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "107 SW Sandlewood Loop", "city": "Gresham", "state":"Oregon", "zipcode": "97030"},
    "display_address": "107 SW Sandlewood Loop",
    "property": {
      "listing_price": "$399,900",
      "bedrooms": "4",
      "baths": "2.5",
      "sq_feet": "1874",
      "lot_size": "4162",
      "rmls": "20518751",
      "built_in": "2004",
      "blurb": "Great open floor plan in quiet private neighborhood! Light and bright kitchen with white cabinets, tile counters, eating bar, new disposal, convection oven and pantry. Dining area with sliding door to private deck. Family room with gas fireplace. Large primary suite with vaulted ceilings, walk-in closet, private bath with dual sinks and soaking tub. Large resurfaced back deck overlooking protected wooded views ~ great for entertaining. Close to Southwest Community Park, 34.1 acres. Must see!",
      "ammenities": [

      ]
    },
    "image_count": 25,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.6921362928315!2d-122.4667775844415!3d45.49614397910135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549598bbe54096af%3A0x69ad915b85d300f8!2s107%20SW%20Sandlewood%20Loop%2C%20Gresham%2C%20OR%2097030!5e0!3m2!1sen!2sus!4v1604000469175!5m2!1sen!2sus",
        "youtube":"F4AdJSl8KFI",
        "matterport":"kfgThaX6VoZ"
    }
  }

export default propertyData;

